import { createRouter, createWebHistory } from 'vue-router'
import QuizSettings from '@/views/QuizSettings.vue'
import QuizGame from '@/views/QuizGame.vue'

const routes = [
  {
    path: '/',
    name: 'settings',
    component: QuizSettings
  },
  {
    path: '/quiz/:categories/:mode/:maxQuestions',
    name: 'quiz',
    component: QuizGame,
    props: route => ({
      categories: JSON.parse(route.params.categories || '[]'),
      mode: route.params.mode,
      maxQuestions: parseInt(route.params.maxQuestions) || 20
    })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
