export const questionPacks = [
  {
    id: 'easy',
    name: 'Лёгкий старт',
    description: 'Базовые вопросы для начинающих',
    maxQuestions: 10,
    icon: '🌟'
  },
  {
    id: 'brain',
    name: 'Супер нагруз',
    description: 'Сложные вопросы для тренировки мозга',
    maxQuestions: 20,
    icon: '🧠'
  },
  {
    id: 'math',
    name: 'Математический',
    description: 'Вопросы по математике и логике',
    maxQuestions: 15,
    icon: '🔢'
  },
  {
    id: 'medical',
    name: 'Медицинский',
    description: 'Вопросы о здоровье и первой помощи',
    maxQuestions: 15,
    icon: '🏥'
  },
  {
    id: 'social',
    name: 'Общество и право',
    description: 'Вопросы о политике, праве и обществе',
    maxQuestions: 15,
    icon: '⚖️'
  },
  {
    id: 'culture',
    name: 'Культурный код',
    description: 'Вопросы о литературе, искусстве и истории',
    maxQuestions: 15,
    icon: '🎭'
  }
]
