<template>
  <div class="container mt-4">
    <div class="card mx-auto bg-dark text-white" style="max-width: 600px;">
      <div class="card-header bg-secondary">
        <h3 class="card-title">Выберите предметы и режим</h3>
      </div>
      <div class="card-body">
        <div class="mb-4">
          <h5>Готовые паки вопросов:</h5>
          <div class="row g-2">
            <div v-for="pack in questionPacks" :key="pack.id" class="col-md-6">
              <div 
                class="card h-100 bg-secondary cursor-pointer"
                :class="{ 'border-primary': isPackSelected(pack.id) }"
                @click="selectPack(pack.id)"
              >
                <div class="card-body">
                  <h6 class="card-title">{{ pack.icon }} {{ pack.name }}</h6>
                  <p class="card-text small mb-1">{{ pack.description }}</p>
                  <small class="text-light">
                    {{ getPackCategories(pack.id).join(', ') }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h5>Выберите режим:</h5>
        <div class="mb-3">
          <div class="form-check">
            <input 
              type="radio" 
              class="form-check-input" 
              id="multipleChoice"
              value="multiple"
              v-model="selectedMode"
            >
            <label class="form-check-label" for="multipleChoice">Выбор из 4 вариантов</label>
          </div>
          <div class="form-check">
            <input 
              type="radio" 
              class="form-check-input" 
              id="inputMode"
              value="input"
              v-model="selectedMode"
            >
            <label class="form-check-label" for="inputMode">Ввод ответа</label>
          </div>
        </div>

        <div class="mb-3">
          <h5>Максимальное количество вопросов:</h5>
          <select 
            class="form-select bg-dark text-white" 
            v-model="maxQuestions"
          >
            <option value="10">10 вопросов</option>
            <option value="15">15 вопросов</option>
            <option value="20">20 вопросов</option>
            <option value="25">25 вопросов</option>
            <option value="30">30 вопросов</option>
          </select>
        </div>

        <h5>Выберите категории:</h5>
        <div class="mb-3" v-for="category in categories" :key="category">
          <div class="form-check">
            <input 
              type="checkbox" 
              class="form-check-input" 
              :id="category"
              :checked="selectedCategories.has(category)"
              @change="toggleCategory(category)"
            >
            <label class="form-check-label" :for="category">{{ category }}</label>
          </div>
        </div>
        <button 
          class="btn btn-primary w-100" 
          @click="startQuiz"
          :disabled="selectedCategories.size === 0 || !selectedMode"
        >
          Начать викторину
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { questionPacks } from '@/config/questionPacks'

export default {
  name: 'QuizSettings',
  data() {
    return {
      categories: [],
      selectedCategories: new Set(),
      selectedMode: 'multiple',
      maxQuestions: 20,
      questionPacks,
      selectedPack: null,
      packsData: []
    }
  },
  async created() {
    await this.fetchCategories()
    await this.fetchPacks()
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await fetch('/api/categories')
        const data = await response.json()
        this.categories = data.categories
        this.selectedCategories = new Set()
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    },
    toggleCategory(category) {
      const newCategories = new Set(this.selectedCategories)
      if (newCategories.has(category)) {
        if (newCategories.size > 1) {
          newCategories.delete(category)
        }
      } else {
        newCategories.add(category)
      }
      this.selectedCategories = newCategories
    },
    startQuiz() {
      this.$router.push({
        name: 'quiz',
        params: {
          categories: JSON.stringify(Array.from(this.selectedCategories)),
          mode: this.selectedMode,
          maxQuestions: this.maxQuestions
        }
      })
    },
    selectPack(packId) {
      const categories = this.getPackCategories(packId)
      this.selectedCategories = new Set(categories)
      const pack = this.questionPacks.find(p => p.id === packId)
      if (pack) {
        this.maxQuestions = pack.maxQuestions
      }
    },
    isPackSelected(packId) {
      return this.selectedPack?.id === packId
    },
    async fetchPacks() {
      try {
        const response = await fetch('/api/packs')
        const data = await response.json()
        this.packsData = data
      } catch (error) {
        console.error('Error fetching packs:', error)
      }
    },
    getPackCategories(packId) {
      const pack = this.packsData.find(p => p.id === packId)
      return pack ? pack.categories : []
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.card.bg-secondary {
  transition: all 0.3s ease;
}

.card.bg-secondary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.border-primary {
  border: 2px solid #0d6efd !important;
}
</style>
