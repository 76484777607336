<template>
  <div class="container mt-4">
    <div class="card mx-auto bg-dark text-white" style="max-width: 600px;">
      <div class="card-header bg-secondary">
        <h3 class="card-title">
          <template v-if="loading">
            Загрузка...
          </template>
          <template v-else-if="questions.length === 0">
            Нет доступных вопросов
          </template>
          <template v-else>
            {{ !showResult ? `${questions[currentQuestion].category} - Вопрос ${currentQuestion + 1} из ${questions.length}` : 'Результаты' }}
          </template>
        </h3>
      </div>
      <div class="card-body">
        <div v-if="loading" class="text-center">
          <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Загрузка...</span>
          </div>
        </div>

        <div v-else-if="questions.length === 0" class="text-center">
          <p>Нет доступных вопросов для выбранных категорий</p>
          <button class="btn btn-primary" @click="resetQuiz">
            Вернуться к выбору категорий
          </button>
        </div>

        <div v-else-if="!showResult && questions.length > 0">
          <div class="timer mb-3">
            <div class="progress">
              <div 
                class="progress-bar" 
                role="progressbar" 
                :style="{ width: (100 - ((quizTimer - timer) / quizTimer * 100)) + '%' }"
                :class="{
                  'bg-success': timer / quizTimer > 0.5,
                  'bg-warning': timer / quizTimer <= 0.5 && timer / quizTimer > 0.25,
                  'bg-danger': timer / quizTimer <= 0.25
                }"
              >
                {{ timer }}
              </div>
            </div>
          </div>
          <h4 class="mb-4">{{ questions[currentQuestion].question }}</h4>

          <!-- Multiple Choice Mode -->
          <div v-if="selectedMode === 'multiple'">
            <div class="d-grid gap-2">
              <button
                v-for="(option, index) in questions[currentQuestion].options"
                :key="index"
                class="btn btn-outline-light"
                :class="{
                  'btn-success': answered && correctAnswer === index,
                  'btn-danger': answered && selectedAnswer === index && selectedAnswer !== correctAnswer,
                  'btn-secondary': answered && selectedAnswer === null
                }"
                @click="handleAnswer(index)"
                :disabled="answered"
              >
                {{ option }}
              </button>
            </div>
          </div>

          <!-- Input Mode -->
          <div v-else-if="selectedMode === 'input'">
            <div class="input-group mb-3">
              <input 
                type="text" 
                class="form-control" 
                v-model="inputAnswer" 
                :disabled="answered" 
                placeholder="Введите ваш ответ"
                @keyup.enter="submitInputAnswer"
                :class="{
                  'is-valid': inputAnswerStatus === 'correct',
                  'is-invalid': inputAnswerStatus === 'incorrect'
                }"
              >
              <button 
                class="btn btn-primary" 
                @click="submitInputAnswer"
                :disabled="answered || inputAnswer.trim() === ''"
              >
                Отправить ответ
              </button>
            </div>
            <div v-if="answered" class="mt-2">
              <div v-if="inputAnswerStatus === 'correct'" class="text-success">
                Правильно! ✓
              </div>
              <div v-else-if="inputAnswerStatus === 'incorrect'" class="text-danger">
                Неправильно! Правильный ответ: {{ correctAnswer }}
              </div>
            </div>
          </div>

          <div class="mt-4 d-flex gap-2">
            <button 
              class="btn btn-danger w-50"
              @click="stopQuiz"
            >
              Закончить викторину
            </button>
            <button 
              v-if="answered"
              class="btn btn-primary w-50"
              @click="nextQuestion"
            >
              {{ currentQuestion === questions.length - 1 ? 'Показать результаты' : 'Следующий вопрос' }}
            </button>
          </div>
        </div>

        <div v-else class="text-center">
          <h3 class="mb-4">Ваш результат: {{ score }} из {{ questions.length }}</h3>
          
          <!-- Detailed answers report -->
          <div class="answers-report">
            <div class="accordion accordion-flush" id="answersAccordion">
              <div v-for="(answer, index) in userAnswers" :key="index" class="accordion-item">
                <h2 class="accordion-header">
                  <button 
                    class="accordion-button collapsed" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="'#collapse' + index"
                    :aria-expanded="false"
                    :aria-controls="'collapse' + index"
                  >
                    Вопрос {{ index + 1 }} - {{ answer.category }}
                    <span 
                      class="ms-2 badge"
                      :class="answer.isCorrect ? 'bg-success' : 'bg-danger'"
                    >
                      {{ answer.isCorrect ? 'Верно' : 'Неверно' }}
                    </span>
                  </button>
                </h2>
                <div 
                  :id="'collapse' + index" 
                  class="accordion-collapse collapse" 
                  data-bs-parent="#answersAccordion"
                >
                  <div class="accordion-body">
                    <p class="mb-2"><strong>Вопрос:</strong> {{ answer.question }}</p>
                    <p class="mb-2">
                      <strong>Ваш ответ: </strong> 
                      <span :class="answer.isCorrect ? 'text-success' : 'text-danger'">
                        {{ answer.userAnswer }}
                      </span>
                    </p>
                    <p v-if="!answer.isCorrect" class="mb-0">
                      <strong>Правильный ответ: </strong> 
                      <span class="text-success">{{ answer.correctAnswer }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button class="btn btn-primary w-100 mt-4" @click="resetQuiz">
            Начать заново
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizGame',
  props: {
    categories: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    maxQuestions: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      loading: false,
      questions: [],
      currentQuestion: 0,
      score: 0,
      showResult: false,
      answered: false,
      selectedAnswer: null,
      correctAnswer: null,
      error: null,
      timerInterval: null,
      quizTimer: 6,
      timer: 6,
      userAnswers: [],
      selectedMode: this.mode,
      inputAnswer: '',
      inputAnswerStatus: null,
    }
  },
  async created() {
    this.loading = true
    try {
      await this.fetchQuestions()
      if (this.questions.length > 0) {
        this.startTimer()
      }
    } catch (error) {
      this.error = 'Ошибка при загрузке вопросов'
      console.error('Error starting quiz:', error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    // Copy all methods from QuizApp.vue except toggleCategory and startQuiz
    // Modify fetchQuestions to use route params
    async fetchQuestions() {
      try {
        const response = await fetch('/api/questions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            categories: this.categories,
            maxQuestions: this.maxQuestions
          })
        })
        
        if (!response.ok) {
          throw new Error('Failed to fetch questions')
        }
        
        const data = await response.json()
        this.questions = data.questions
      } catch (error) {
        console.error('Error fetching questions:', error)
        this.questions = []
        throw error
      }
    },
    resetQuiz() {
      this.clearTimer()
      this.$router.push({ name: 'settings' })
    },
    async handleAnswer(index) {
      if (this.answered) return
      this.selectedAnswer = index
      this.answered = true
      this.clearTimer()

      try {
        const response = await fetch('/api/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            question: this.questions[this.currentQuestion],
            answer: index
          })
        })
        const data = await response.json()
        this.correctAnswer = data.correctAnswer
        
        // Store answer details
        this.userAnswers.push({
          question: this.questions[this.currentQuestion].question,
          userAnswer: this.questions[this.currentQuestion].options[index],
          correctAnswer: this.questions[this.currentQuestion].options[data.correctAnswer],
          isCorrect: data.correct,
          category: this.questions[this.currentQuestion].category
        })
        
        if (data.correct) {
          this.score++
        }
      } catch (error) {
        console.error('Error verifying answer:', error)
      }
    },
    async submitInputAnswer() {
      if (this.answered || this.inputAnswer.trim() === '') return;
      
      fetch('/api/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          question: this.questions[this.currentQuestion],
          answer: this.inputAnswer.trim()
        })
      })
      .then(response => response.json())
      .then(data => {
        this.answered = true;
        this.correctAnswer = data.correctAnswer;
        this.inputAnswerStatus = data.correct ? 'correct' : 'incorrect';
        
        // Store answer details
        this.userAnswers.push({
          question: this.questions[this.currentQuestion].question,
          userAnswer: this.inputAnswer.trim(),
          correctAnswer: data.correctAnswer,
          isCorrect: data.correct,
          category: this.questions[this.currentQuestion].category
        });
        
        if (data.correct) {
          this.score++;
        }
      })
      .catch(error => {
        console.error('Error verifying answer:', error);
      });
    },
    nextQuestion() {
      this.clearTimer();
      if (this.currentQuestion < this.questions.length - 1) {
        this.currentQuestion++;
        this.answered = false;
        this.selectedAnswer = null;
        this.correctAnswer = null;
        this.inputAnswer = '';
        this.inputAnswerStatus = null;
        this.startTimer();
      } else {
        this.showResult = true;
      }
    },
    startTimer() {
      this.timer = this.quizTimer;  // Set initial timer value
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.handleTimeUp();
        }
      }, 1000);
    },
    handleTimeUp() {
      clearInterval(this.timerInterval);
      if (!this.answered) {
        this.answered = true;
        if (this.selectedMode === 'multiple') {
          fetch('/api/verify', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              question: this.questions[this.currentQuestion],
              answer: -1
            })
          })
          .then(response => response.json())
          .then(() => {
            const correctAnswerIndex = this.questions[this.currentQuestion].correct;
            this.correctAnswer = this.questions[this.currentQuestion].options[correctAnswerIndex];
            this.selectedAnswer = null;
            
            this.userAnswers.push({
              question: this.questions[this.currentQuestion].question,
              userAnswer: 'Нет ответа',
              correctAnswer: this.questions[this.currentQuestion].options[correctAnswerIndex],
              isCorrect: false,
              category: this.questions[this.currentQuestion].category
            });
          })
          .catch(error => {
            console.error('Error getting correct answer:', error);
          });
        } else if (this.selectedMode === 'input') {
          // Handle time up for input mode
          this.userAnswers.push({
            question: this.questions[this.currentQuestion].question,
            userAnswer: 'Нет ответа',
            correctAnswer: this.questions[this.currentQuestion].options[this.questions[this.currentQuestion].correct],
            isCorrect: false,
            category: this.questions[this.currentQuestion].category
          });
        }
      }
    },
    clearTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },
    stopQuiz() {
      this.clearTimer();
      this.showResult = true;
    },
  }
}
</script>

<style scoped>
.card {
  background-color: #2c2c2c;
  border: none;
}

.card-header {
  background-color: #1a1a1a;
  border-bottom: 1px solid #444;
}

.btn-primary {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-success {
  background-color: #198754;
  border-color: #198754;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  background-color: #f8f9fa;
  color: #2c2c2c;
}

.progress-bar.bg-success {
  background-color: #198754 !important;
}

.progress-bar.bg-warning {
  background-color: #ffc107 !important;
}

.progress-bar.bg-danger {
  background-color: #dc3545 !important;
}

.text-white {
  color: #f8f9fa !important;
}

.text-success {
  color: #198754 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.bg-dark {
  background-color: #121212 !important;
}

.bg-secondary {
  background-color: #1a1a1a !important;
}

.answers-report .accordion-button {
  background-color: #2c2c2c;
  color: #f8f9fa;
}

.answers-report .accordion-button:not(.collapsed) {
  background-color: #343a40;
  color: #f8f9fa;
}

.answers-report .accordion-body {
  background-color: #1a1a1a;
  color: #f8f9fa;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.badge.bg-success {
  background-color: #198754;
}

.badge.bg-danger {
  background-color: #dc3545;
}

.input-group .form-control.is-valid {
  border-color: #198754;
  background-color: #198754;
  color: white;
}

.input-group .form-control.is-invalid {
  border-color: #dc3545;
  background-color: #dc3545;
  color: white;
}

.input-group .form-control.is-valid::placeholder,
.input-group .form-control.is-invalid::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
</style>