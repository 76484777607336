<template>
  <div id="app" class="bg-dark text-white min-vh-100">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  background-color: #121212;
  color: #f8f9fa;
}
</style>
